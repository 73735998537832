import React from 'react';
import './App.css';
import {Grid, Paper, Container, Typography, Button, Breadcrumbs, Chip} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton'
import Link from '@material-ui/core/Link';


function encodeHTML(s) {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
}

export default class FeaturedComponent extends React.Component {
	constructor(props) {
		super(props)
        this.state = { 
            data: [], 
            loading: true, 
            q: (props.match.params.query),
         };
        
        if (props.match.params.query === undefined) {
            window.location.href="/?e=ns";
        }

        //Fetch advanced filter params from url
        let params = new URLSearchParams(this.props.location.search);
        this.state.filtered = params.get("filtered");
        this.state.from = params.get("from");
        this.state.to = params.get("to");
        this.state.order = params.get("order");
        this.state.topic = params.get("topic");
    }
    
    
	loadData(props) {
	    const TG_KEY = process.env.REACT_APP_TG_KEY
	    if (this.state.q === 'covid') {
            //Speical for COVID19
	        fetch('https://content.guardianapis.com/search?q=' + encodeHTML(this.state.q) + '&page-size=30&section=world&order-by=newest&api-key=' + TG_KEY)
	            .then(response => response.json())
	            .then(data => {
	                this.setState({
	                    data: data.response.results
	                })
	                this.setState({
	                    loading: false
	                });
	            })
	            .catch(err => console.error(this.props.url, err.toString()))
	        this.setState({
	            loading: true
	        });
	    } else if(this.state.filtered === 'true') {
           var url = "";
           if(this.state.topic == 'none'){
            url = ('https://content.guardianapis.com/search?q=' + encodeHTML(this.state.q) + '&page-size=30&order-by='+this.state.order+'&api-key=' + TG_KEY); 
           }else{
            url = ('https://content.guardianapis.com/search?q=' + encodeHTML(this.state.q) + '&page-size=30&section='+this.state.topic+'&order-by='+this.state.order+'&from-date='+this.state.from+'&to-date='+this.state.to+'&api-key=' + TG_KEY);
           }
	        fetch(url)
	            .then(response => response.json())
	            .then(data => {
	                this.setState({
	                    data: data.response.results
	                })
	                this.setState({
	                    loading: false
	                });
	            })
	            .catch(err => console.error(this.props.url, err.toString()))
	        this.setState({
	            loading: true
	        });
	    } else {
	        fetch('https://content.guardianapis.com/search?q=' + encodeHTML(this.state.q) + '&page-size=30&order-by=relevance&api-key=' + TG_KEY)
	            .then(response => response.json())
	            .then(data => {
	                this.setState({
	                    data: data.response.results
	                })
	                this.setState({
	                    loading: false
	                });
	            })
	            .catch(err => console.error(this.props.url, err.toString()))
	        this.setState({
	            loading: true
	        });
	    }

	}

    
    
    getPlaceHolder() {
        return (
            <Grid item xs={12} sm={6} md={4} className="feSection">
            <Paper variant="outlined" className="fePaper">
                <Typography variant="overline">
                <Skeleton animation="wave"/>
                </Typography>
                <div className="feFooter">
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <Typography variant="overline">
                                <Skeleton animation="wave"/>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Skeleton animation="wave"/>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </Grid>
        );
    }

    getFilters() {
        return (
            <div>
                <Chip className="advChip" label={'Between ' + this.state.from + ' & ' + this.state.to} variant="outlined" />
                <Chip className="advChip" label={this.state.topic} variant="outlined" />
                <Chip className="advChip" label={this.state.order} variant="outlined" />
            </div>
        );
    }

	componentDidMount() {
        this.loadData()
    }   
   
  render() {
    //Loading doesn't currently work because elements arn't created until api is loaded
    const loading = this.state.loading;
    const ph = this.getPlaceHolder();
    const filter = this.getFilters();
    return (
        <div className="worldRoot">
            <div className="catBanner">
                <Container maxWidth="lg">
                    <Typography variant="h4" className="feHeading">Results for '{this.state.q}'</Typography>
                    <Typography variant="overline" className="catSmallText">Displaying up to 30 results</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/">
                            Home
                        </Link>
                        <Typography color="textPrimary">Search</Typography>
                    </Breadcrumbs><br></br>
                    {this.state.filtered && filter}
                </Container>
            </div>
            <Container maxWidth="lg">
                { loading ?
                    <Grid container spacing={3} alignItems="stretch">
                    {ph}
                    {ph}
                    {ph}
                    </Grid>
                : null  }            
                <Grid container spacing={3} alignItems="stretch">
                { this.state.data.slice(0, 15).map((item, i) => {
				return <Grid item xs={12} sm={6} md={4} className="feSection">
                    <Paper variant="outlined" className="fePaper"  style={{ borderRadius: 8 }}>
                        <Typography variant="overline">
                        {loading ? <Skeleton animation="wave"/> : item.sectionName}
                        </Typography>
                        <div className="feContent">
                            <Typography variant="h6" paragraph="true" className="feItemH6">
                                {item.webTitle}
                            </Typography>
                        </div>
                        <div className="feFooter">
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Typography variant="overline">
                                        {loading ? <Skeleton animation="wave"/> : new Date(item.webPublicationDate).toDateString()}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Button href={item.webUrl} target="_blank" color="primary" className="feLink">Read More</Button><br/>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </Grid>
        })
      }
            </Grid>
        </Container>
      </div>
    );
  }
}	



