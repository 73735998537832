import './App.css';
import NavbarComponent from './components/NavbarComponent'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import {blue} from '@material-ui/core/colors'
import Homepage from './Homepage'
import World from './World'
import Business from './Business'
import Technology from './Technology'
import Entertainment from './Entertainment'
import Sports from './Sports'
import Science from './Science'
import Environment from './Environment'
import Search from './Search'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[600],
    },
    secondary: {
      main: '#014173',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans TC', 
      'sans-serif',
    ]},
})

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className="App">
          <header>
          <NavbarComponent/>
          </header>
          <Route exact path="/" component={Homepage}/>
          <Route exact path="/world" component={World}/>
          <Route exact path="/business" component={Business}/>
          <Route exact path="/technology" component={Technology}/>
          <Route exact path="/entertainment" component={Entertainment}/>
          <Route exact path="/sports" component={Sports}/>
          <Route exact path="/science" component={Science}/>
          <Route exact path="/enviroment" component={Environment}/>
          <Route path="/search/:query?" component={Search}/>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
