import React from 'react';
import {Grid, Paper, Container, Typography, Button, Breadcrumbs} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton'
import Link from '@material-ui/core/Link';

export default class FeaturedComponent extends React.Component {
	constructor(props) {
		super(props)
        this.state = { data: [], loading: true }
    }
   	
	loadData() {
        const TG_KEY = process.env.REACT_APP_TG_KEY  
		fetch('https://content.guardianapis.com/search?section=world&page-size=15&api-key='+TG_KEY)
			.then(response => response.json())
			.then(data => {
                this.setState({data: data.response.results})
                this.setState({ loading: false });
		})
            .catch(err => console.error(this.props.url, err.toString()))
                this.setState({ loading: true });
            
            
    }
    
    getPlaceHolder() {
        return (
            <Grid item xs={12} sm={6} md={4} className="feSection">
            <Paper variant="outlined" className="fePaper">
                <Typography variant="overline">
                <Skeleton animation="wave"/>
                </Typography>
                <div className="feFooter">
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <Typography variant="overline">
                                <Skeleton animation="wave"/>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Skeleton animation="wave"/>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </Grid>
        );
    }

	componentDidMount() {
        this.loadData()
    }   
   
  render() {
    //Loading doesn't currently work because elements arn't created until api is loaded
    const loading = this.state.loading;
    const ph = this.getPlaceHolder();
    return (
        <div className="worldRoot">
            <div className="catBanner">
                <Container maxWidth="lg">
                    <Typography variant="h4" className="feHeading">World News</Typography>
                    <Typography variant="overline" className="catSmallText">Displaying 15 results</Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/">
                            Home
                        </Link>
                        <Typography color="textPrimary">World</Typography>
                    </Breadcrumbs>
                </Container>
            </div>
            <Container maxWidth="lg">
                { loading ?
                    <Grid container spacing={3} alignItems="stretch">
                    {ph}
                    {ph}
                    {ph}
                    </Grid>
                : null  }            
                <Grid container spacing={3} alignItems="stretch">
                { this.state.data.slice(0, 15).map((item, i) => {
				return <Grid item xs={12} sm={6} md={4} className="feSection">
                    <Paper variant="outlined" className="fePaper"  style={{ borderRadius: 8 }}>
                        <Typography variant="overline">
                        {loading ? <Skeleton animation="wave"/> : item.sectionName}
                        </Typography>
                        <div className="feContent">
                            <Typography variant="h6" paragraph="true" className="feItemH6">
                                {item.webTitle}
                            </Typography>
                        </div>
                        <div className="feFooter">
                            <Grid container spacing={3}>
                                <Grid item xs>
                                    <Typography variant="overline">
                                        {loading ? <Skeleton animation="wave"/> : new Date(item.webPublicationDate).toDateString()}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Button href={item.webUrl} target="_blank" color="primary" className="feLink">Read More</Button><br/>
                                </Grid>
                            </Grid>
                        </div>
                    </Paper>
                </Grid>
        })
      }
            </Grid>
        </Container>
      </div>
    );
  }
}	



