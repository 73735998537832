import './App.css';
import React from 'react';
import FeaturedComponent from './components/FeaturedComponent'
import CategoryComponent from './components/CategoryComponent'
import SearchBarComponent from './components/SearchBarComponent'
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import {Container, Collapse, IconButton} from '@material-ui/core';

function SearchAlert() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    if (window.location.href.indexOf("e=ns") > -1) {
        return (
            <div className={classes.root}>
                <Container maxWidth="lg">
                    <Collapse in={open}>
                        <Alert
                            severity="warning"
                            action={
                                <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }>
                            Please enter a search term in box below to begin
                        </Alert>
                    </Collapse>
                </Container>
            </div>
        );
    }else{
        return null;
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      marginTop: 15,
    },
  }));
  

export default class Homepage extends React.Component {
    render() {
        return (
            <div>
                <SearchAlert/>
                <CategoryComponent/>
                <SearchBarComponent/>
                <FeaturedComponent/>
            </div>
        );
    }
}
