import React from 'react';
import '../App.css';
import {Container, Chip} from '@material-ui/core';
import PublicRoundedIcon from '@material-ui/icons/PublicRounded';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import MemoryRoundedIcon from '@material-ui/icons/MemoryRounded';
import LiveTvRoundedIcon from '@material-ui/icons/LiveTvRounded';
import SportsBasketballRoundedIcon from '@material-ui/icons/SportsBasketballRounded';
import EcoRoundedIcon from '@material-ui/icons/EcoRounded';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import { green, indigo, blue, orange, deepOrange, purple, grey, red } from '@material-ui/core/colors';

export default function NavbarComponent(props) {  
    return (
        <div className={"catRoot"}>
            <Container maxWidth="lg">
              <div className={"catChips"}>
                  <Chip variant="outlined" component="a" href="/world" clickable icon={<PublicRoundedIcon style={{ color: indigo[400] }}/>} label="World" />
                  <Chip variant="outlined" component="a" href="/business" clickable icon={<BusinessRoundedIcon style={{ color: purple[600] }}/>} label="Business"/>
                  <Chip variant="outlined" component="a" href="/technology" clickable icon={<MemoryRoundedIcon style={{ color: blue[300] }}/>} label="Technology"/>
                  <Chip variant="outlined" component="a" href="/entertainment" clickable icon={<LiveTvRoundedIcon style={{ color: purple[900] }}/>} label="Entertainment"/>
                  <Chip variant="outlined" component="a" href="/sports" clickable icon={<SportsBasketballRoundedIcon style={{ color: orange[400] }}/>} label="Sports"/>
                  <Chip variant="outlined" component="a" href="/science" clickable icon={<MenuBookRoundedIcon style={{ color: deepOrange[500] }}/>} label="Science"/>
                  <Chip variant="outlined" component="a" href="/enviroment" clickable icon={<EcoRoundedIcon style={{ color: green[700] }}/>} label="Enviroment"/> 
                  <Chip component="a" href="/search/covid" clickable style={{ background: red[500], color: grey[50] }} icon={<FavoriteRoundedIcon style={{ color: grey[50] }}/>} label="COVID-19"/> 
              </div>
            </Container>
      </div>
    );
  }