import React, { useState } from 'react';
import '../App.css';
import {Container, InputAdornment, FormControl, OutlinedInput, IconButton, Tooltip, TextField, MenuItem, Select, InputLabel, Grid, Collapse, Button, Snackbar} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';


  function encodeHTML(s) {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
  }

  function triggerSearch(searchTerm, filter, topic, orderBy) {
    //Basic check to determine if a search term was entered
    if(searchTerm === null || searchTerm === ''){
      //Return to index
      window.location.href = encodeHTML('/?e=ns');
    }else{
      //Carry out search
      if(filter){
        var fromDate = document.getElementById("fromDate").value;
        var toDate = document.getElementById("toDate").value;
        window.location.href = ('/search/'+ searchTerm+'?filtered=true&from='+fromDate+'&to='+toDate+'&order='+orderBy+'&topic='+topic);
      }else{
        window.location.href = encodeHTML('/search/'+ searchTerm);
      }
    }
  }


export default function NavbarComponent() {
    //State Variables
    const [checked, setChecked] = useState(false);
    const [advFilters, setAdvFilters] = useState(false);
    const [searchTerm, setsearchTerm] = useState('');
    const [advCategory, setadvCategory] = useState('none');
    const [advOrderBy, setadvOrderBy] = useState('newest');

    //Snackbar
    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    
    //Hide & Show the advanced search options
    const advancedChange = () => {
      setChecked((prev) => !prev);
    };
  
    //Handle text input & update search term    
    const handleChange = (prop) => (event) => {
      //Search Text
      if(prop === 'searchTerm') {
        setsearchTerm(event.target.value);
      }
      //Filter Toggle
      if(prop === 'advancedFilter') {
        //If Adv 'apply' button has be pressed
        if(event.currentTarget.id === 'af-apply') {
          setAdvFilters(true);
          setOpen(true);
          advancedChange();
        }else{
          setAdvFilters(false);
        }
      }
      //Adv Category Select
      if(prop === 'category') {
        setadvCategory(event.target.value);
      }

      //Adv Order Select
      if(prop === 'orderby') {
        setadvOrderBy(event.target.value);
      }
    };
    
    //Search icon clicked
    const handleIconClick = () => {
      triggerSearch(searchTerm, advFilters, advCategory, advOrderBy);
    };
    
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    //Handle 'enter' key press for search
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        triggerSearch(searchTerm, advFilters, advCategory, advOrderBy);
      }
    };

    
    return (
      <div className={"sbcRoot"}>
        <Container maxWidth="lg">
          {/* Main Search Box */}
          <FormControl fullWidth variant="outlined">
            <OutlinedInput className={"sbcSearchBar"}
              id="outlined-adornment-searchTerm"
              value={searchTerm}
              color="primary"
              placeholder="Begin exploring..."
              required
              onChange={handleChange('searchTerm')}
              onKeyPress={handleKeyPress}
              //Advanced Search Button
              endAdornment={
                <Tooltip title="Advanced Search">
                  <InputAdornment position="end">
                    <IconButton
                        aria-label="Advanced Search"
                        onClick={advancedChange}
                        edge="end"
                        color="secondary"
                    >
                    <TuneRoundedIcon/>
                    </IconButton>
                  </InputAdornment>
                </Tooltip>                         
              }
              //Search Botton
              startAdornment={
                <InputAdornment position="start">
                    <IconButton
                        aria-label="Search"
                        onClick={handleIconClick}
                        onMouseDown={handleMouseDownPassword}
                        edge="start"
                        color="primary"
                    >
                    <SearchIcon/>
                    </IconButton>
                </InputAdornment>
              }
              aria-describedby="outlined-searchTerm-helper-text"
              inputProps={{
              'aria-label': 'searchTerm',
              }}
              labelWidth={0}
            />
          </FormControl>
          {/* Advanced Search Options */}
          <Collapse in={checked} className={"sbcTopSpacer"}>
            <form className={"sbcContainer"} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={3}>
                  {/* From Date Field */}
                  <TextField
                      id="fromDate"
                      label="From date"
                      type="date"
                      size="small"
                      defaultValue="2020-01-01"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      /><br></br>
                      {/* To Date Field */}
                      <TextField
                      id="toDate"
                      label="To date"
                      type="date"
                      size="small"
                      defaultValue={new Date().toISOString().split('T')[0]}
                      className={"sbcTopSpacer"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                  {/* Section/Category Selector */}
                  <FormControl className={"sbcFormCtrl"}>
                  <InputLabel id="lbltopic">Category</InputLabel>
                  <Select
                    labelId="lbltopic"
                    id="topic"
                    label="Category"
                    size="small"
                    defaultValue="None"
                    onChange={handleChange('category')}
                  >
                    <MenuItem value="None" selected={true}>None</MenuItem>
                    <MenuItem value={'artanddesign'}>Art & Design</MenuItem>
                    <MenuItem value={'australia-news'}>Australian News</MenuItem>
                    <MenuItem value={'books'}>Books</MenuItem>
                    <MenuItem value={'business'}>Business</MenuItem>
                    <MenuItem value={'community'}>Community</MenuItem>
                    <MenuItem value={'culture'}>Culture</MenuItem>
                    <MenuItem value={'environment'}>Environment</MenuItem>
                    <MenuItem value={'fashion'}>Fashion</MenuItem>
                    <MenuItem value={'film'}>Film</MenuItem>
                    <MenuItem value={'food'}>Food</MenuItem>
                    <MenuItem value={'games'}>Games</MenuItem>
                    <MenuItem value={'lifeandstyle'}>Life & Style</MenuItem>
                    <MenuItem value={'media'}>Media</MenuItem>
                    <MenuItem value={'money'}>Money</MenuItem>
                    <MenuItem value={'music'}>Music</MenuItem>
                    <MenuItem value={'news'}>News</MenuItem>
                    <MenuItem value={'politics'}>Politics</MenuItem>
                    <MenuItem value={'science'}>Science</MenuItem>
                    <MenuItem value={'society'}>Society</MenuItem>
                    <MenuItem value={'sport'}>Sport</MenuItem>
                    <MenuItem value={'technology'}>Technology</MenuItem>
                    <MenuItem value={'travel'}>Travel</MenuItem>
                    <MenuItem value={'weather'}>Weather</MenuItem>
                    <MenuItem value={'world'}>World</MenuItem>
                  </Select>
                </FormControl><br></br>
                {/* Order By Selector */}
                <FormControl className={'sbcFormCtrl' +' sbcTopSpacer'}>
                  <InputLabel id="lblorder">Order By</InputLabel>
                  <Select
                    labelId="lblorder"
                    id="orderBy"
                    label="Order By"
                    size="small"
                    defaultValue="newest"
                    onChange={handleChange('orderby')}
                  >
                    <MenuItem value="newest" selected={true}>Newest</MenuItem>
                    <MenuItem value="oldest">Oldest</MenuItem>
                    <MenuItem value="relevance">Relevance</MenuItem>
                  </Select>
                </FormControl>
                <Container className={"sbcBtnContainer"}>
                  <Button value="false" id="af-reset" onClick={handleChange('advancedFilter')}>Reset</Button>
                  <Button color="primary" id="af-apply" onClick={handleChange('advancedFilter')}>Apply</Button>
                </Container>
                </Grid>
                <Grid item xs={12} sm={3}></Grid>
              </Grid>
            </form>
          </Collapse>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Filters Applied"
          />
        </Container>
      </div>
    );
  }