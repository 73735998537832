import React from 'react';
import '../App.css';
import {AppBar, Container, Toolbar, InputBase, Typography, IconButton, Tooltip, Avatar} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {grey} from '@material-ui/core/colors'


  //Prepare search for use in api call
  function encodeHTML(s) {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
  }

  export default function NavbarComponent() {
    const [values, setValues] = React.useState({
      searchTerm: '',
    });
    
    //Handle changes to the input
    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };

    //Handle 'Enter' presses & blank searches
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        //On Enter Press - basic check to determine if a search term was entered
        if(values.searchTerm === null || values.searchTerm === ''){
          //Return to index
          window.location.href = encodeHTML('/?e=ns');
        }else{
          //Carry out search
          window.location.href = encodeHTML('/search/'+ values.searchTerm);
        }
      }
    };
  
    return (
      <div className={"navRoot"}>
        <AppBar position="static">
            <Container maxWidth="lg">
                <Toolbar>
                    <Avatar href="/" component="a" alt="GN" className={"navIcon"} src={"/logo192.png"}/>
                    <Typography href="/" component="a" className={"navTitle"} variant="h5" noWrap>Guardian Navigator</Typography>
                    <Typography href="/" component="a" className={"navAltTitle"} variant="h5">GN</Typography>
                    <div className={"navSearch"}>
                        <InputBase 
                            placeholder="Quick Search…"
                            onChange={handleChange('searchTerm')}
                            onKeyPress={handleKeyPress}
                            classes={{
                            root: "navSearchRoot",
                            input: "navSearchInput",
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
      </div>
    );
  }